<template>
  <section id="dashboard-ecommerce">
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="primary"
      opacity="0.85"
      blur="2"
      rounded="sm"
    >
      <div>
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col cols="12" md="2" class="mb-md-0 mb-2">
                <label>Grup</label>
                <v-select
                  label="name"
                  taggable
                  :options="productGroups"
                  placeholder="Grup seçebilirsiniz"
                  v-model="selectedGroup"
                  class="w-100"
                >
                  <template #selected-option="{ name }">
                    <strong style="font-size: 10px; line-height: 22px">{{
                      name
                    }}</strong>
                  </template>
                </v-select>
              </b-col>
              <b-col cols="12" md="2" class="mb-md-0 mb-2">
                <label>İskonto</label>
                <b-form-input
                  v-model="discountGroup"
                  class="d-inline-block mr-1"
                  placeholder="İskonto Giriniz"
                />
              </b-col>
              
              <b-col cols="12" md="1" class="mb-md-0 mb-2">
                <div class="d-flex mt-2">
                  <b-button
                    style="margin-top: 0px"
                    type="button"
                    variant="primary"
                    @click="saveGroupDiscount"
                  >
                    <span class="align-middle">Kaydet</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row> </b-row>
          </b-card-body>
        </b-card>
        <b-card no-body class="mb-0">
          <b-table
            :striped="true"
            :bordered="true"
            :hover="true"
            :busy="show"
            :small="true"
            ref="refUserListTable"
            class="position-relative"
            :items="groups"
            responsive
            :fields="tableColumns"
            primary-key="id"
            sort-by.sync="id"
            show-empty
            empty-text="Eşleşen ürün bulunamadı."
            head-variant="dark"
          >
            <template #cell(groups)="data">
              <div class="item-quantity d-flex justify-content">
                {{ data.item.group.name }}
              </div>
            </template>
            <template #cell(discountFormatted)="data">
              <div class="item-quantity d-flex justify-content-end">
                <strong>{{ data.item.discountFormatted }}</strong>
              </div>
            </template>
            <template #cell(cart)="data">
              <div class="d-flex justify-content-center">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="showDiscountPanelModal(data.item)">
                    <feather-icon icon="ArrowDownIcon" />
                    <span class="align-middle ml-50">İskonto Belirle</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteGroup(data.item.id)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Sil</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2"></div>
        </b-card>
      </div>
      <b-modal
        id="modal-center"
        centered
        title="Ürün Görseli"
        :hide-footer="true"
      >
        <b-img :src="selectedImgUrl" fluid alt="Fluid image')" />
      </b-modal>
      <b-modal
        id="modal-cartImport"
        v-model="showUploadPanel"
        centered
        title="Excelden Ürün Girişi"
        :hide-footer="true"
      >
        <b-form-file
          v-model="file"
          placeholder="Bir dosya seçin veya buraya bırakın"
          drop-placeholder="Dosyayı buraya bırakın.."
          accept=".xlsx, .xls"
        />
        <br />
        <b-card-text>
          Ürün bilgi güncellemesi fiyat değişimi yada yeni ürün girişi için
          excel dosyanızı yükleye bilirsiniz.
        </b-card-text>
        <br />
        <div class="item-quantity d-flex justify-content-center">
          <div class="d-flex mt-2">
            <b-button
              style="margin-top: 0x"
              type="button"
              variant="primary"
              @click="cartUpload"
            >
              <feather-icon icon="UploadIcon" class="mr-50" />
              <span class="align-middle">Yükle</span>
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-modal
        id="modal-cartImport"
        v-model="showImagePanel"
        centered
        title="Görsel Ekleme"
        :hide-footer="true"
      >
        <b-form-file
          v-model="imageFile"
          placeholder="Bir dosya seçin veya buraya bırakın"
          drop-placeholder="Dosyayı buraya bırakın.."
          accept=".jpg, .jpeg, .png"
        />

        <br />
        <div class="item-quantity d-flex justify-content-center">
          <div class="d-flex mt-2">
            <b-button
              style="margin-top: 0x"
              type="button"
              variant="primary"
              @click="imageUpload"
            >
              <feather-icon icon="UploadIcon" class="mr-50" />
              <span class="align-middle">Yükle</span>
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-modal
        id="modal-cartImport"
        v-model="showDiscountPanel"
        centered
        title="Grup İskonto Belirleme"
        :hide-footer="true"
      >
        <b-form-group label="İskonto oranı % " label-for="number">
          <cleave
            id="number"
            v-model="selectedProduct.discount"
            class="form-control"
            :raw="false"
            :options="number"
          />
        </b-form-group>

        <br />
        <div class="item-quantity d-flex justify-content-center">
          <div class="d-flex mt-2">
            <b-button
              style="margin-top: 0x"
              type="button"
              variant="primary"
              @click="discountSave()"
            >
              <feather-icon icon="ArrowUpIcon" class="mr-50" />
              <span class="align-middle">Kaydet</span>
            </b-button>
          </div>
        </div>
      </b-modal>
    </b-overlay>
  </section>
</template>

<script>
import {
  BFormInput,
  BOverlay,
  BModal,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BButton,
  BFormRadioGroup,
  BFormGroup,
  BCol,
  BTable,
  BFormFile,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BImg,
  BFormSpinbutton,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";
import Cleave from "vue-cleave-component";

export default {
  components: {
    BRow,
    Cleave,
    BImg,
    BCol,
    BCard,
    ToastificationContent,
    BModal,
    BOverlay,
    BFormInput,
    BCardHeader,
    BCardBody,
    BButton,
    BFormSpinbutton,
    vSelect,
    BTable,
    BMedia,
    BFormRadioGroup,
    BFormGroup,
    BAvatar,
    BFormFile,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  data() {
    return {
      productsData: [],
      cleanProdcuts: [],
      groups: [],
      selectedGroup: null,
      brands: [],
      selectedBrand: null,
      models: [],
      selectedModel: null,
      partNo: "",
      oeNo: "",
      tableColumns: [
        { key: "groups", label: "Ürün Grubu", class: "productLongButton" },
        { key: "discountFormatted", label: "İskonto", class: "discountButton" },
        { key: "cart", label: "İşlem", class: "productButton" },
      ],
      pageLoad: false,
      show: false,
      selectedImgUrl: "",
      levels: [
        { text: "TÜMÜ", value: "ALL" },
        { text: "A", value: "A" },
        { text: "B", value: "B" },
        { text: "C", value: "C" },
        { text: "D", value: "D" },
      ],
      selectedLevel: "ALL",
      imageFile: null,
      file: null,
      showUploadPanel: false,
      showDiscountPanel: false,
      showImagePanel: false,
      selectedProduct: {},
      number: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    //
    async init() {
      this.show = true;
      this.selectedGroup = null;
      this.discountGroup=null;
      this.selectedBrand = null;
      this.selectedModel = null;
      this.oeNo = "";
      this.partNo = "";

      this.productsData.pageNo = 1;
      this.productsData.pageSize = 10;
      this.searchClick(1);

      await this.$http.get("/Product/GetProductGroups").then((response) => {
        this.productGroups = response.data.result;
      });
      await this.$http.get("/Product/GetAllGroupDiscount").then((response) => {
        this.groups = response.data.result;
      });
      this.show = false;
    },
    deleteGroup(id) {
      this.show = true;
      this.$http
        .post(`/Product/DeleteGroupDiscount?id=${id}`)
        .then((response) => {
          this.init();
          this.$swal({
            title: "Başarılı!",
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        });
    },
    showImagePanelModal(item) {
      this.showImagePanel = true;
      this.selectedProduct = item;
    },
    showDiscountPanelModal(item) {
      this.showDiscountPanel = true;
      this.selectedProduct = item;
      console.log(this.selectedProduct.discount);
    },
    discountSave() {
      this.show = true;
      const req = {
        groupId: this.selectedProduct.group.id,
        discount: this.selectedProduct.discount,
      };

      this.$http
        .post(`/Product/EditGroupDiscount`, req)
        .then((response) => {
          this.showDiscountPanel = false;
          this.init();
          this.$swal({
            title: "Başarılı!",
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        });
    },
    imageUpload() {
      if (this.imageFile === null) {
        this.$swal({
          title: "Uyarı!",
          text: "Lütfen önce dosya seçiniz.",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          confirmButtonText: "Kapat",
          buttonsStyling: false,
        });
        return;
      }

      this.show = true;
      this.showImagePanel = false;
      let formData = new FormData();
      formData.append("file", this.imageFile);
      this.$http
        .post(
          `Product/ImageUploadForProduct?productId=${this.selectedProduct.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.show = false;
          this.init();
          this.$swal({
            title: "İşlem başarılı!",
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
          this.imageFile = null;
        })
        .catch((error) => {
          this.show = false;
          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
          this.imageFile = null;
        });
    },
    changePage(pageNo) {
      //Burası biraz karışık ama tek fonksyon ile 3 iş yapınca sonuç böyle oluyor...
      if (pageNo != null && pageNo > 1) {
        this.searchClick(pageNo);
        this.pageLoad = true;
      }

      if (pageNo == 1 && this.pageLoad) {
        this.searchClick(pageNo);
        this.pageLoad = false;
      }
    },
    changeLevel() {
      this.searchClick(1);
    },
    searchClick(pageNo) {
      this.show = true;
      let bid = 0;
      let mid = 0;
      let gid = 0;

      if (this.selectedBrand != null) {
        bid = this.selectedBrand.id;
      }

      if (this.selectedModel != null) {
        mid = this.selectedModel.id;
      }

      if (this.selectedGroup != null) {
        gid = this.selectedGroup.id;
      }

      this.$http
        .get(
          `Product/GetProductsForAdmin?BrandId=${bid}&ModelId=${mid}&GroupId=${gid}&OeNo=${this.oeNo}&PartNo=${this.partNo}&Level=${this.selectedLevel}&PageSize=${this.productsData.pageSize}&PageIndex=${pageNo}`
        )
        .then((response) => {
          this.productsData = response.data.result;
          this.cleanProdcuts = this.productsData.items;
          if (this.selectedLevel != "ALL") {
            this.productsData = this.productsData.filter(
              (s) => s.level == this.selectedLevel
            );
          }
          this.show = false;
        })
        .catch((error) => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        });
    },
    saveGroupDiscount() {
      this.show = true;

      if (this.selectedGroup === null||this.discountGroup===null) {
        this.$swal({
          title: "Uyarı!",
          text: "Lütfen önce grup ve iskonto belirleyiniz.",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary"
          },
          confirmButtonText: "Kapat",
          buttonsStyling: false
        });
        return;
      }
       const req = {
        groupId: this.selectedGroup.id,
        discount: this.discountGroup,
      };
       this.$http
        .post(`/Product/EditGroupDiscount`, req)
        .then((response) => {
          this.showDiscountPanel = false;
          this.init();
          this.$swal({
            title: "Başarılı!",
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        });
    },
    showImageClick(data) {
      this.selectedImgUrl = data.item.coverImgUrl;
    },
    addCartClick(data) {
      this.show = true;
      this.$http
        .get(
          `Cart/AddCartProduct?productId=${data.item.id}&productUnit=${data.item.cartUnit}`
        )
        .then((response) => {
          data.item.cartUnit = 0;
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Başarılı",
              icon: "CartIcon",
              text: response.data.message,
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        });
    },
    cartUpload() {
      if (this.file === null) {
        this.$swal({
          title: "Uyarı!",
          text: "Lütfen önce dosya seçiniz.",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          confirmButtonText: "Kapat",
          buttonsStyling: false,
        });
        return;
      }

      this.show = true;
      this.showUploadPanel = false;
      let formData = new FormData();
      formData.append("file", this.file);
      this.$http
        .post(`Product/ImportProductsWithExcel`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.show = false;
          this.init();
          this.$swal({
            title: "İşlem başarılı!",
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
          this.file = null;
        })
        .catch((error) => {
          this.show = false;
          console.log(error);
          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
          this.file = null;
        });
    },
    downloadSample() {
      window.open("https://document.d7ksuper.com/Dokuman/b2b/OrnekSiparis.xlsx", "_blank");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";

.table .bTableThStyle {
  max-width: 8rem !important;
  text-overflow: ellipsis !important;
}
.table .productButton {
  width: 4.5rem !important;
  text-overflow: ellipsis !important;
}
.table .productUnitButton {
  width: 8rem !important;
  text-overflow: ellipsis !important;
}
.table .productYearButton {
  width: 5rem !important;
  text-overflow: ellipsis !important;
}
.table .discountButton {
  width: 3rem !important;
  text-overflow: ellipsis !important;
}
.table .productLongButton {
  max-width: 12rem !important;
  text-overflow: ellipsis !important;
}
.table .productLongTwoButton {
  max-width: 15rem !important;
  text-overflow: ellipsis !important;
}
.table .productLongThButton {
  max-width: 17rem !important;
  text-overflow: ellipsis !important;
}
</style>